export default [
  {
    path: 'barcode',
    titleTab: 'Barcode/QRCode',
    tableHeaders: [
      {
        key: 'idCard',
        label: 'ID Card'
      },
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'refCode',
        label: 'Reference No.'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'ipAddress',
        label: 'IP Address'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'installment',
    titleTab: 'Installment Information',
    tableHeaders: [
      {
        key: 'idCard',
        label: 'ID Card'
      },
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'customerName',
        label: ' Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'totalOutstandingBalance',
        label: 'Total Outstanding Balance'
      },
      {
        key: 'nextPaymentAmount',
        label: 'Next Payment Amount'
      },
      {
        key: 'nextPaymentDueDate',
        label: 'Next Payment Due Date'
      },
      {
        key: 'overdueAmount',
        label: 'Overdue Amount'
      },
      {
        key: 'overdueDueDate',
        label: 'Overdue Due Date'
      },
      {
        key: 'overduePeriod',
        label: 'Overdue Period'
      },
      {
        key: 'amount',
        label: 'Amount'
      },
      {
        key: 'penaltyAmt',
        label: 'Penalty Amt.'
      },
      {
        key: 'callCharge',
        label: 'Call Charge'
      },
      {
        key: 'taxPaymentAmount',
        label: 'Tax Payment Amount'
      },
      {
        key: 'taxPaymentDueDate',
        label: 'Tax Payment Due Date'
      },
      {
        key: 'transferPaymentDueDate',
        label: 'Transfer Payment Due Date'
      },
      {
        key: 'riPaymentAmount',
        label: 'Ri Payment Amount'
      },
      {
        key: 'riPaymentDueDate',
        label: 'Ri Payment DueDate'
      },
      {
        key: 'transferPaymentAmount',
        label: 'Transfer Payment Amount'
      },
      {
        key: 'asOfDate',
        label: 'As Of Date'
      },
      {
        key: 'ipAddress',
        label: 'IP Address'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'onlinePayment',
    titleTab: 'Online Payment',
    tableHeaders: [
      {
        key: 'idCard',
        label: 'ID Card'
      },
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'customerName',
        label: ' Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'ref1',
        label: 'Ref1'
      },
      {
        key: 'bankName',
        label: 'Bank Name'
      },
      {
        key: 'amount',
        label: 'Amount'
      },
      {
        key: 'transactionNo',
        label: 'Transaction No.'
      },
      {
        key: 'ipAddress',
        label: 'IP Address'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'register',
    titleTab: 'Register Line Connect',
    tableHeaders: [
      {
        key: 'idCard',
        label: 'ID Card'
      },
      {
        key: 'registerDate',
        label: 'Transaction Date'
      },
      {
        key: 'contractUnderId',
        label: 'Contract Under ID'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'contractDigit',
        label: 'Contract No.'
      },
      {
        key: 'statusName',
        label: 'Status'
      },
      {
        key: 'ipAddress',
        label: 'IP Address'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'unregister',
    titleTab: 'Unregister',
    tableHeaders: [
      {
        key: 'idCard',
        label: 'ID Card'
      },
      {
        key: 'unregisterDate',
        label: 'Transaction Date'
      },
      {
        key: 'contractUnderId',
        label: 'Contract Under ID'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'ipAddress',
        label: 'IP Address'
      }
    ],
    search: 'Customer Name',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'otp',
    titleTab: 'OTP',
    tableHeaders: [
      {
        key: 'idCard',
        label: 'ID Card'
      },
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'telephone',
        label: 'Telephone'
      },
      {
        key: 'otpCode',
        label: 'OTP Code'
      },
      {
        key: 'otpTypeDetail',
        label: 'OTP Type Detail'
      },
      {
        key: 'ipAddress',
        label: 'IP Address'
      }
    ],
    search: 'ID Card',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'contractInfomation',
    titleTab: 'Contract Infomation',
    tableHeaders: [
      {
        key: 'idCard',
        label: 'ID Card'
      },
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'asOfDate',
        label: 'As Of Date'
      },
      {
        key: 'ipAddress',
        label: 'IP Address'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'requestContract',
    titleTab: 'Request Contract',
    tableHeaders: [
      {
        key: 'idCard',
        label: 'ID Card'
      },
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'type',
        label: 'Type'
      },
      {
        key: 'ipAddress',
        label: 'IP Address'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'notiInstallment',
    titleTab: 'Notification Installment',
    tableHeaders: [
      {
        key: 'identificatiotionNo',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'amount',
        label: 'Amount'
      },
      {
        key: 'dueDate',
        label: 'Due Date'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'notiOverdue',
    titleTab: 'Notification Overdue',
    tableHeaders: [
      {
        key: 'identificatiotionNo',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: ' Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'amount',
        label: 'Amount'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'notiOverdue1',
    titleTab: 'Notification Overdue 1',
    tableHeaders: [
      {
        key: 'identificatiotionNo',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'notiReceipt',
    titleTab: 'Notification Receipt',
    tableHeaders: [
      {
        key: 'identificatiotionNo',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'recieptDate',
        label: 'Receipt Date'
      },
      {
        key: 'amount',
        label: 'Amount'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'notiReceiptCancel',
    titleTab: 'Notification Receipt Cancel',
    tableHeaders: [
      {
        key: 'identificatiotionNo',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: ' Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'amount',
        label: 'Amount'
      },
      {
        key: 'recieptCancelDate',
        label: 'Receipt Cancel Date'
      },
      // {
      //     key: "status",
      //     label: "Status"
      // },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'notiReturnCash',
    titleTab: 'Notification Return Cash',
    tableHeaders: [
      {
        key: 'identificatiotionNo',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: ' Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'returnCashDate',
        label: 'Return Cash Date'
      },
      {
        key: 'amount',
        label: 'Amount'
      },
      {
        key: 'bankName',
        label: 'Bank Name'
      },
      {
        key: 'accountName',
        label: 'Account Name'
      },
      // {
      //     key: "status",
      //     label: "Status"
      // },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'notiRi',
    titleTab: 'Notification RI',
    tableHeaders: [
      {
        key: 'identificatiotionNo',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: ' Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },

      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'dueDate',
        label: 'Due Date'
      },
      {
        key: 'amount',
        label: 'Amount'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'notiTax',
    titleTab: 'Notification Tax',
    tableHeaders: [
      {
        key: 'identificatiotionNo',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: ' Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'dueDate',
        label: 'Due Date'
      },
      {
        key: 'amount',
        label: 'Amount'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'map',
    titleTab: 'Map',
    tableHeaders: [
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'idCard',
        label: ' ID card'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contract',
        label: 'Contract'
      },
      {
        key: 'aDorSKL',
        label: 'AD or SKL'
      },
      {
        key: 'location',
        label: 'Location'
      },
      {
        key: 'productType',
        label: 'Product Type'
      },
      {
        key: 'ipAddress',
        label: 'IP address'
      }
    ],
    search: 'Line Name, ID card',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10,
      AdOrSKl: [],
      searchText: ''
    },
    totalRowMessage: ''
  },
  {
    path: 'checkapp',
    titleTab: 'Check App',
    tableHeaders: [
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'idCard',
        label: ' ID card'
      },
      {
        key: 'customerName',
        label: 'Customer Name'
      },
      {
        key: 'lineConnect',
        label: 'Line Connect'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'appNo',
        label: 'App No.'
      },
      {
        key: 'statusApp',
        label: 'Status App'
      },
      {
        key: 'asOfDate',
        label: 'As of Date'
      },
      {
        key: 'ipAddress',
        label: 'IP address'
      }
    ],
    search: 'Customer Name, Line Name, ID Card',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10,
      statusApp: [],
      searchText: ''
    },
    totalRowMessage: ''
  },
  {
    path: 'calculator',
    titleTab: 'Calculator',
    tableHeaders: [
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'idCard',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: 'Customer Name'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'ipAddress',
        label: 'IP address'
      }
    ],
    search: 'Line Name, ID Card',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10,
      searchText: ''
    },
    totalRowMessage: ''
  },
  {
    path: 'point',
    titleTab: 'Point',
    tableHeaders: [
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'idCard',
        label: ' ID card'
      },
      {
        key: 'customerName',
        label: 'Customer Name'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contract',
        label: 'Contract'
      },
      {
        key: 'ipAddress',
        label: 'IP address'
      }
    ],
    search: 'Line Name',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10,
      searchText: ''
    },
    totalRowMessage: ''
  },
  {
    path: 'notiPoint',
    titleTab: 'Notification Point',
    tableHeaders: [
      {
        key: 'IDCard',
        label: 'ID Card',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'lineName',
        label: 'Line Name',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'MobileNo',
        label: 'Mobile No.',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'contractNo',
        label: 'Contract No.',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'sklPoint',
        label: 'Point',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'expireDate',
        label: 'Expire Date'
      },
      {
        key: 'read',
        label: 'Status'
      },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date'
      }
    ],
    search: 'Customer Name',
    items: [],
    filter: {
      startFilterDate: '',
      endFilterDate: '',
      searchText: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'notiRedeemPoint',
    titleTab: 'Notification Redeem',
    tableHeaders: [
      {
        key: 'identificatiotionNo',
        label: 'ID Card',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'lineName',
        label: 'Line Name',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'redeemPoint',
        label: 'คะแนนที่ใช้แลก',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'refCode',
        label: 'หมายเลขรายการ',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date',
        class: 'w-100px text-nowrap'
      }
    ],
    search: 'Customer Name',
    items: [],
    filter: {
      startFilterDate: '',
      endFilterDate: '',
      searchText: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'notiEarnPoint',
    titleTab: 'Notification Earn Point',
    tableHeaders: [
      {
        key: 'identificatiotionNo',
        label: 'ID Card',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'customerName',
        label: 'Customer Name',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'lineName',
        label: 'Line Name',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'contractNo',
        label: 'Contrack No.',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'addPointDate',
        label: 'Date ที่อยู่ใน flex',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'addPoint',
        label: 'SKL Point ที่ได้รับ',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'totalPoint',
        label: 'SKL Point รวม',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date',
        class: 'w-100px text-nowrap'
      }
    ],
    search: 'Customer Name',
    items: [],
    filter: {
      startFilterDate: '',
      endFilterDate: '',
      searchText: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  },
  {
    path: 'promotion',
    titleTab: 'Promotion',
    tableHeaders: [
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'idCard',
        label: 'ID Card',
        class: 'w-100px text-nowrap'
      },
      {
        key: 'customerName',
        label: 'Customer Name'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contract',
        label: 'Contract'
      },
      {
        key: 'word_Url',
        label: 'Word URL'
      },
      {
        key: 'ipAddress',
        label: 'IP address'
      }
    ],
    search: 'ID Card, Line Name, Contract',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10,
      searchText: ''
    },
    totalRowMessage: ''
  },
  {
    path: 'LoanOnlineRegister',
    titleTab: 'Loan Online Register',
    tableHeaders: [
      {
        key: 'transactionDate',
        label: 'Transaction Date'
      },
      {
        key: 'idCard',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: 'Customer Name'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'ipAddress',
        label: 'IP address'
      }
    ],
    search: 'Customer Name, Line Name, ID Card',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10,
      searchText: ''
    },
    totalRowMessage: ''
  },
  {
    path: 'notiInvoice',
    titleTab: 'Notification Invoice',
    tableHeaders: [
      {
        key: 'notificationSendDate',
        label: 'Notification Send Date'
      },
      {
        key: 'identificatiotionNo',
        label: 'ID Card'
      },
      {
        key: 'customerName',
        label: ' Customer Name',
        class: 'columnCustomerName'
      },
      {
        key: 'lineName',
        label: 'Line Name'
      },
      {
        key: 'contractNo',
        label: 'Contract No.'
      },
      {
        key: 'mobileNo',
        label: 'Mobile No.'
      },
      {
        key: 'carModel',
        label: 'Car Model'
      },
      {
        key: 'roundDate',
        label: 'Round Date'
      },
      {
        key: 'fileName',
        label: 'File Name'
      },
      {
        key: 'fileName',
        label: 'File Name'
      },
      {
        key: 'fileDownloadURL',
        label: 'File Download URL'
      },
      {
        key: 'type',
        label: 'Type'
      },
      {
        key: 'result',
        label: 'Send Result'
      },
      {
        key: 'resultCode',
        label: 'Result Code'
      },
      {
        key: 'resultDescription',
        label: 'Result Description'
      }
    ],
    search: 'Customer Name, ID Card, Contract No.',
    items: [],
    filter: {
      userId: null,
      startFilterDate: '',
      endFilterDate: '',
      page: 1,
      pageSize: 10
    },
    totalRowMessage: ''
  }
];
