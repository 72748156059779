<template>
  <div>
    <div class="min-vh-100">
      <CRow class="w-100 no-gutters">
        <CCol class="text-center text-lg-left">
          <h1 class="mr-sm-4">REPORT {{ titleName }}</h1>
        </CCol>
        <CCol lg="6" class="text-center text-lg-right">
          <b-button v-b-toggle.sidebar-1 class="btn-filter mr-0 mr-sm-1">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">FILTER</span>
          </b-button>
          <b-dropdown
            id="dropdown-1"
            class="mr-2 btn-mains small-dropdown medium-dd"
            right
            no-flip
          >
            <template v-slot:button-content>
              <font-awesome-icon
                icon="ellipsis-v"
                title="filter-btn"
                class="text-white d-sm-none"
              />
              <span class="d-none d-sm-inline">ACTION</span>
            </template>
            <b-dropdown-item @click="exportData(1)"
              >Export Excel</b-dropdown-item
            >
            <b-dropdown-item @click="exportData(2)">Export CSV</b-dropdown-item>
          </b-dropdown>
        </CCol>
      </CRow>
      <b-sidebar
        id="sidebar-1"
        title="FILTER"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
              @click="onClearFilter()"
            >
              Clear
            </button>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <p class="font-weight-bold my-2">Start Date</p>
              <datetime
                v-model="filter.startFilterDate"
                placeholder="Please select date"
                class="date-filter"
                format="dd MMM yyyy"
              ></datetime>
            </div>
            <div class="col-6">
              <p class="font-weight-bold my-2">End Date</p>
              <datetime
                v-model="filter.endFilterDate"
                placeholder="Please select date"
                :min-datetime="minTime"
                class="date-filter"
                format="dd MMM yyyy"
              ></datetime>
            </div>
            <div class="col-12">
              <p class="text-err-date" v-if="errDate">
                กรุณาเลือกวันที่เริ่มต้น
              </p>
            </div>
          </div>

          <div v-if="reportType == 'map' || reportType == 'checkapp'">
            <p class="font-weight-bold my-2">Status</p>
          </div>

          <div class="row" v-if="reportType == 'map'">
            <div class="col-6" v-for="status in statusList" :key="status.id">
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="status.name"
                  v-model="filter.AdOrSKl"
                  checked="checked"
                  :id="'status-' + status.id"
                />
                <label class="form-check-label" :for="'status-' + status.id">{{
                  status.name
                }}</label>
              </div>
            </div>
          </div>

          <div class="row" v-if="reportType == 'checkapp'">
            <div class="col-6" v-for="status in statusList" :key="status.id">
              <div class="form-check mb-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="status.id"
                  v-model="filter.statusApp"
                  checked="checked"
                  :id="'status-' + status.id"
                />
                <label class="form-check-label" :for="'status-' + status.id">{{
                  status.name
                }}</label>
              </div>
            </div>
          </div>

          <div class="text-center mt-3">
            <button
              type="button"
              class="btn btn-main button"
              @click="handleFilterDate()"
            >
              Submit
            </button>
          </div>
        </div>
      </b-sidebar>
      <div class="bg-white-border px-4 px-sm-5 py-4 mt-4">
        <b-row class="no-gutters justify-content-between">
          <b-col md="5" class="px-0 px-lg-3 py-3 px-2 pt-lg-2">
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                :placeholder="searchPlaceHolder"
                v-model="filter.searchText"
                @keyup="handleSearch"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-4">
            <b-table
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              thead-class="text-nowrap"
              tbody-class="text-nowrap"
            >
              <template v-slot:cell(transactionDate)="data">
                <div v-if="data.item.transactionDate == null">-</div>
                <div v-else>
                  <span>
                    {{ data.item.transactionDate | moment($formatDateTime) }}
                  </span>
                </div>
              </template>
              <template v-slot:cell(asOfDate)="data">
                <div
                  v-if="data.item.asOfDate == null || data.item.asOfDate == ''"
                >
                  -
                </div>
                <div v-else>
                  <span>{{ data.item.asOfDate | moment($formatDate) }}</span>
                </div>
              </template>
              <template v-slot:cell(lineConnect)="data">
                <div
                  v-if="
                    data.item.lineConnect == null || data.item.lineConnect == ''
                  "
                >
                  -
                </div>
                <div v-else>
                  <span>{{ data.item.lineConnect | moment($formatDate) }}</span>
                </div>
              </template>
              <template v-slot:cell(contract)="data">
                <div
                  v-if="data.item.contract == null || data.item.contract == ''"
                >
                  -
                </div>
                <div v-else>
                  <span>{{ data.item.contract }}</span>
                </div>
              </template>
              <template v-slot:cell(refCode)="data">
                <div
                  v-if="data.item.refCode == null || data.item.refCode == ''"
                >
                  -
                </div>
                <div v-else>
                  <span>{{ data.item.refCode }}</span>
                </div>
              </template>
              <template v-slot:cell(read)="data">
                <div v-if="data.item.read == true">เปิดอ่านแล้ว</div>
                <div v-else>
                  <span>ยังไม่เปิดอ่าน</span>
                </div>
              </template>
              <template v-slot:cell(nextPaymentDueDate)="data">
                <div v-if="data.item.nextPaymentDueDate == null">-</div>
                <div v-else>
                  <span>{{
                    data.item.nextPaymentDueDate | moment($formatDate)
                  }}</span>
                </div>
              </template>
              <template v-slot:cell(dueDate)="data">
                <div v-if="data.item.dueDate == null">-</div>
                <div v-else>
                  <span>{{ data.item.dueDate | moment($formatDate) }}</span>
                </div>
              </template>
              <template v-slot:cell(transferPaymentDueDate)="data">
                <div v-if="data.item.transferPaymentDueDate == null">-</div>
                <div v-else>
                  <span>{{
                    data.item.transferPaymentDueDate | moment($formatDate)
                  }}</span>
                </div>
              </template>
              <template v-slot:cell(riPaymentDueDate)="data">
                <div v-if="data.item.riPaymentDueDate == null">-</div>
                <div v-else>
                  <span>{{
                    data.item.riPaymentDueDate | moment($formatDate)
                  }}</span>
                </div>
              </template>

              <template v-slot:cell(overdueDueDate)="data">
                <div v-if="data.item.overdueDueDate == null">-</div>
                <div v-else>
                  <span>{{
                    data.item.overdueDueDate | moment($formatDate)
                  }}</span>
                </div>
              </template>
              <template v-slot:cell(registerDate)="data">
                <div v-if="data.item.registerDate == null">-</div>
                <div v-else>
                  <span>
                    {{ data.item.registerDate | moment($formatDateTime) }}
                  </span>
                </div>
              </template>
              <template v-slot:cell(unregisterDate)="data">
                <div v-if="data.item.unregisterDate == null">-</div>
                <div v-else>
                  <span>
                    {{ data.item.unregisterDate | moment($formatDateTime) }}
                  </span>
                </div>
              </template>

              <template v-slot:cell(addPointDate)="data">
                <div v-if="data.item.addPointDate == null">-</div>
                <div v-else>
                  <span>
                    {{ data.item.addPointDate | moment($formatDate) }}
                  </span>
                </div>
              </template>

              <template v-slot:cell(notificationSendDate)="data">
                <div v-if="data.item.notificationSendDate == null">-</div>
                <div v-else>
                  <span>
                    {{
                      data.item.notificationSendDate | moment($formatDateTime)
                    }}
                  </span>
                </div>
              </template>
              <template v-slot:cell(ipAddress)="data">
                <div
                  v-if="
                    data.item.ipAddress == null || data.item.ipAddress == ''
                  "
                >
                  -
                </div>
                <div v-else>
                  <span>
                    {{ data.item.ipAddress }}
                  </span>
                </div>
              </template>
              <template v-slot:cell(word_Url)="data">
                <div v-if="data.item.word_Url == null">-</div>
                <div v-else>
                  <span>{{ data.item.word_Url }}</span>
                </div>
              </template>
              <template v-slot:cell(recieptDate)="data">
                <div v-if="data.item.recieptDate == null">-</div>
                <div v-else>
                  <span>{{ data.item.recieptDate | moment($formatDate) }}</span>
                </div>
              </template>
              <template v-slot:cell(recieptCancelDate)="data">
                <div v-if="data.item.recieptCancelDate == null">-</div>
                <div v-else>
                  <span>{{ data.item.recieptCancelDate }}</span>
                </div>
              </template>
              <template v-slot:cell(returnCashDate)="data">
                <div v-if="data.item.returnCashDate == null">-</div>
                <div v-else>
                  <span>{{
                    data.item.returnCashDate | moment($formatDate)
                  }}</span>
                </div>
              </template>
              <template v-slot:cell(expireDate)="data">
                <div v-if="data.item.expireDate == null">-</div>
                <div v-else>
                  <span>{{ data.item.expireDate | moment($formatDate) }}</span>
                </div>
              </template>
              <template v-slot:cell(created)="data">
                <div v-if="data.item.created == null">-</div>
                <div v-else>
                  <span>{{ data.item.created }}</span>
                </div>
              </template>
              <template v-slot:cell(lineName)="data">
                <div
                  v-if="data.item.lineName == null || data.item.lineName == ''"
                >
                  -
                </div>
                <div v-else>
                  <span>{{ data.item.lineName }}</span>
                </div>
              </template>
              <template v-slot:cell(customerName)="data">
                <div
                  v-if="
                    data.item.customerName == null ||
                    data.item.customerName == ''
                  "
                >
                  -
                </div>
                <div v-else>
                  <span>{{ data.item.customerName }}</span>
                </div>
              </template>
              <template v-slot:cell(idCard)="data">
                <div v-if="data.item.idCard == null || data.item.idCard == ''">
                  -
                </div>
                <div v-else>
                  <span>{{ data.item.idCard }}</span>
                </div>
              </template>
              <template v-slot:cell(amount)="data">
                <span> {{ data.item.amount | numeral('0,0') }} </span>
              </template>
              <template v-slot:cell(taxPaymentAmount)="data">
                <span>
                  {{ data.item.taxPaymentAmount | numeral('0,0.0') }}
                </span>
              </template>
              <template v-slot:cell(taxPaymentDueDate)="data">
                <span v-if="data.item.taxPaymentDueDate === ''"> - </span>
                <span v-else>
                  {{ data.item.taxPaymentDueDate }}
                </span>
              </template>
              <template v-slot:cell(productType)="data">
                <div
                  v-if="
                    data.item.productType == null || data.item.productType == ''
                  "
                >
                  -
                </div>
                <div v-else>
                  <span>{{ data.item.productType }}</span>
                </div>
              </template>

              <template v-slot:cell(roundDate)="data">
                <span v-if="data.item.roundDate">
                  {{ data.item.roundDate | moment($formatDate) }}
                </span>

                <span v-else> - </span>
              </template>
              <template v-slot:cell(carModel)="data">
                <span>
                  {{ data.item.carModel || '-' }}
                </span>
              </template>
              <template v-slot:cell(type)="data">
                <span>
                  {{ data.item.type || '-' }}
                </span>
              </template>
              <template v-slot:cell(result)="data">
                <span>
                  {{ data.item.result ? 'สำเร็จ' : 'ไม่สำเร็จ' }}
                </span>
              </template>
              <template v-slot:cell(resultCode)="data">
                <span>
                  {{ data.item.resultCode || '-' }}
                </span>
              </template>
              <template v-slot:cell(resultDescription)="data">
                <span>
                  {{ data.item.resultDescription || '-' }}
                </span>
              </template>

              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="form-inline justify-content-center justify-content-md-between"
          >
            <b-row>
              <b-col>
                <div class="d-inline-block layout-paging">
                  <b-pagination
                    v-model="filter.page"
                    :total-rows="rows"
                    :per-page="filter.pageSize"
                    class="m-md-0"
                    @change="pagination"
                    align="center"
                  ></b-pagination>
                </div>
                <div class="d-inline-block ml-md-3 layout-paging">
                  <p class="total-record-paging text-nowrap text-center">
                    {{ totalRowMessage }}
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-form-select
              v-model="filter.pageSize"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal ref="loadingModal" hide-header hide-footer centered>
      <div class="text-center">
        <img
          src="@/assets/images/icons/loading.svg"
          alt="loading"
          class="mb-3 w-25"
        />
        <h1 class="font-weight-bold text-modal">
          In progress. Exporting Data...
        </h1>
      </div>
    </b-modal>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import axios from 'axios';
import formReports from '@/components/reports/FormTableReports.js';
import ModalAlertError from '@/components/modal/alert/ModalAlertError';
export default {
  name: 'report',
  components: {
    formReports,
    ModalAlertError
  },
  data() {
    return {
      reportType: this.$route.params.id,
      formReports,
      titleName: '',
      searchPlaceHolder: '',
      currentTable: null,
      fields: [],
      items: [],
      statusList: [],
      isBusy: true,
      rows: 0,
      filter: {
        searchText: '',
        startFilterDate: '',
        endFilterDate: '',
        fileTypeId: 1,
        page: 1,
        pageSize: 10
      },
      pageOptions: [
        { value: 10, text: '10 / page' },
        { value: 30, text: '30 / page' },
        { value: 50, text: '50 / page' },
        { value: 100, text: '100 / page' }
      ],
      totalRowMessage: '',
      modalMessage: '',

      // min time
      minTime: null,

      // toggle status
      errDate: false
    };
  },
  watch: {
    '$route.params.id'(value) {
      this.reportType = value;
      // this.filter = {
      //   searchText: "",
      //   startFilterDate: "",
      //   endFilterDate: "",
      //   fileTypeId: 1,
      //   page: 1,
      //   pageSize: 10
      // };
      this.getIndexTable();
      this.getList();
      if (this.reportType == 'map' || this.reportType == 'checkapp') {
        this.getStatusList();
      }
    },
    'filter.startFilterDate'(val) {
      if (val) {
        let getStartDate = new Date(val);
        let getEndtDate = new Date(this.filter.endFilterDate);

        if (getStartDate > getEndtDate) {
          this.filter.endFilterDate = '';
        }

        this.minTime = getStartDate.toISOString();
      } else {
        this.minTime = null;
      }
      this.errDate = false;
    },
    'filter.endFilterDate'() {
      if (!this.filter.startFilterDate) {
        this.errDate = true;
        setTimeout(() => {
          this.filter.endFilterDate = '';
        }, 100);
      }
    }
  },
  mounted: async function () {
    this.$isLoading = true;
    await this.getIndexTable();
    await this.getList();
    if (this.reportType == 'map' || this.reportType == 'checkapp') {
      await this.getStatusList();
    }
    this.$isLoading = false;
  },
  methods: {
    getIndexTable: async function () {
      this.formReports.map((table, i) => {
        if (table.path == this.$route.params.id) {
          this.currentTable = i;
          this.titleName = table.titleTab.toUpperCase();
        }
      });
    },
    getStatusList: async function () {
      var path = '';
      if (this.reportType == 'map') {
        this.filter.AdOrSKl = [];
        path = 'branchType';
      } else {
        this.filter.statusApp = [];
        path = 'checkApp/status';
      }

      let resData = await this.$callApi(
        'get',
        `${this.$baseUrl}/api/` + path,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.statusList = resData.detail;
      }
    },
    getList: async function () {
      this.searchPlaceHolder = this.formReports[this.currentTable].search;
      this.isBusy = true;
      this.$refs.filterSidebar.hide(true);
      this.fields = this.formReports[this.currentTable].tableHeaders;
      this.filter = this.formReports[this.currentTable].filter;

      let resData = await this.$callApi(
        'post',
        `${this.$baseUrl}/api/customer/report/${
          this.formReports[this.currentTable].path
        }`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        for (let i = 0; i < resData.detail.data.length; i++) {
          const element = resData.detail.data[i].dueDate;
          const date = this.$moment(element, 'DD-MM-YYYY').format(
            'MMM/DD/YYYY'
          );
          resData.detail.data[i].dueDate = date;
        }
        this.items = resData.detail.data;
        this.rows = resData.detail.totalData;
        if (resData.detail.totalData > 0) {
          let numRecordFirst = this.filter.page * this.filter.pageSize;
          numRecordFirst = numRecordFirst - this.filter.pageSize + 1;
          let numRecordLast =
            this.filter.page * this.filter.pageSize > resData.detail.totalData
              ? resData.detail.totalData
              : this.filter.page * this.filter.pageSize;
          this.totalRowMessage =
            'Showing ' +
            numRecordFirst +
            ' - ' +
            numRecordLast +
            ' of ' +
            resData.detail.totalData +
            ' entries';
        } else {
          this.totalRowMessage = '';
        }
      }
      this.isBusy = false;
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.pageSize = value;
      this.getList();
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.page = 1;
        this.getList();
      }
    },
    btnSearch() {
      this.filter.page = 1;
      this.getList();
    },
    handleFilterDate() {
      this.filter.page = 1;
      this.getList();
    },
    onClearFilter() {
      this.filter.page = 1;
      this.filter.startFilterDate = '';
      this.filter.endFilterDate = '';
      this.getList();

      setTimeout(() => {
        this.errDate = false;
      }, 100);
    },
    exportData: async function (flagFileType) {
      this.$refs.loadingModal.show();
      let fileType = flagFileType === 2 ? '.csv' : '.xlsx';
      this.filter.fileTypeId = flagFileType;
      axios({
        url: `${this.$baseUrl}/api/customer/export/${
          this.formReports[this.currentTable].path
        }`,
        method: 'post',
        headers: this.$headers,
        responseType: 'blob',
        data: this.filter
      })
        .then(response => {
          this.$refs.loadingModal.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          var dateFile = this.$moment().format('DDMMYYYYhhmmss');

          fileLink.href = fileURL;
          fileLink.setAttribute(
            'download',
            `Report ` +
              this.formReports[this.currentTable].titleTab.trim() +
              `-` +
              dateFile +
              fileType
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => {
          this.$refs.loadingModal.hide();
          this.modalMessage = 'ไม่สามารถ Export ข้อมูลได้';
          this.$refs.modalAlertError.show();
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.text-err-date {
  color: red;
  margin: 10px 0px 0px 0px;
  padding: 0px 3px;
}
</style>
